<template>
  <el-container>
    <div class="box">
      <div class="title_flex">
        <div class="line"></div>
        <div class="text">需求发布</div>
        <div class="line"></div>
      </div>
      <div class="box_two">
        <div class="tab_title">
          <el-tabs v-model="activeName">
            <el-tab-pane class="tab_title" label="" name="first">
              <div slot="label">
                <div class="tab_title_flex_one">十步法发布模式</div>
              </div>
              <div class="bu_zhou_ten">
                <!-- <el-tabs v-model="activeNamees">
                  <el-tab-pane
                    v-for="(item, index) in arr"
                    :key="index"
                    label=""
                    :name="item.xushu"> -->

                <el-steps :active="active" simple>
                  <el-step title="1" @click.native="goActve(0)"></el-step>
                  <el-step title="2" @click.native="goActve(1)"></el-step>
                  <el-step title="3" @click.native="goActve(2)"></el-step>
                  <el-step title="4" @click.native="goActve(3)"></el-step>
                  <el-step title="5" @click.native="goActve(4)"></el-step>
                  <el-step title="6" @click.native="goActve(5)"></el-step>
                  <el-step title="7" @click.native="goActve(6)"></el-step>
                  <el-step title="8" @click.native="goActve(7)"></el-step>
                  <el-step title="9" @click.native="goActve(8)"></el-step>
                  <el-step title="10" @click.native="goActve(9)"></el-step>
                </el-steps>
                <div class="bu_zou">
                  <span class="dang_text">当前步骤</span>
                  <span class="bai_fen"> {{ active + 1 }}</span> <span>/</span>
                  <span>10</span> <span class="wan">填写完整度</span>
                  <el-progress :percentage="percentage"></el-progress>
                </div>
                <el-divider></el-divider>
                <section>
                  <el-form
                    ref="ruleForm"
                    :model="ruleForm"
                    :rules="rules"
                    label-width="80px"
                    autocomplete="off"
                    size="medium"
                  >
                    <div v-if="active == 0" class="demand_one">
                      <div>
                        <el-form-item label="需求名称" prop="title">
                          <el-input
                            v-model="ruleForm.title"
                            placeholder="请填写需求名称"
                          ></el-input>
                        </el-form-item>
                        <el-form-item label="所属领域" prop="territory">
                          <el-select
                            v-model="ruleForm.territory"
                            placeholder="请选择所属领域"
                          >
                            <el-option
                              v-for="item in lingyu"
                              :key="item.id"
                              :label="item.name"
                              :value="item.id"
                            >
                            </el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item label="需求类别" prop="type">
                          <el-radio-group v-model="ruleForm.type">
                            <el-radio
                              v-for="(item, index) in leibie"
                              :key="index"
                              :label="item.id"
                              >{{ item.name }}</el-radio
                            >
                          </el-radio-group>
                        </el-form-item>

                        <el-form-item label="需求地点" prop="address">
                          <el-select
                            v-model="ruleForm.address"
                            placeholder="请选择需求地点"
                          >
                            <el-option
                              v-for="item in di_qv"
                              :key="item.id"
                              :label="item.title"
                              :value="item.id"
                            >
                            </el-option>
                          </el-select>
                        </el-form-item>

                        <!--紧急程度-->
                        <el-form-item label="紧急程度" prop="urgency">
                          <el-radio-group v-model="ruleForm.urgency">
                            <el-radio
                              style="width: 90px"
                              v-for="(item, index) in jinji"
                              :key="index"
                              :label="item.id"
                              >{{ item.name }}</el-radio
                            >
                          </el-radio-group>
                        </el-form-item>
                        <el-form-item label="预算经费" prop="funds">
                          <!-- @mousewheel.native.prevent  type="number"-->
                          <el-input
                            v-model="ruleForm.funds"
                            placeholder="请输入预算经费"
                          ></el-input>
                        </el-form-item>
                      </div>
                      <div>
                        <el-form-item label="公司名称" prop="company_name">
                          <el-input
                            v-model="ruleForm.company_name"
                            placeholder="请填写公司名称"
                          ></el-input>
                        </el-form-item>
                        <el-form-item label="联系人" prop="contacts">
                          <el-input
                            v-model="ruleForm.contacts"
                            placeholder="请填写联系人"
                          ></el-input>
                        </el-form-item>

                        <el-form-item label="联系电话" prop="phone">
                          <el-input
                            v-model="ruleForm.phone"
                            placeholder="请填写联系电话"
                            maxlength="11"
                          ></el-input>
                        </el-form-item>

                        <!--紧急程度-->
                        <el-form-item label="是否公开" prop="is_open">
                          <el-radio-group v-model="ruleForm.is_open">
                            <el-radio :label="1">是</el-radio>
                            <el-radio :label="2">否</el-radio>
                          </el-radio-group>
                        </el-form-item>

                        <el-form-item label="上传图片">
                          <el-upload
                            :on-change="fnI"
                            action=""
                            list-type="picture-card"
                            :on-preview="handlePictureCardPreviewG"
                            :on-remove="handleRemoveG"
                            :auto-upload="false"
                            accept="image/*"
                            :class="{ hide: hideUploadG }"
                            class="avatar-uploader"
                          >
                            <i class="el-icon-plus"></i>
                          </el-upload>
                          <el-dialog :visible.sync="dialogVisible">
                            <img width="100%" :src="dialogImageUrl" alt="" />
                          </el-dialog>
                          <!--上传预览图片右边  -->
                          <div class="demo-image__preview" v-show="show">
                            <el-image
                              style="
                                width: 148px;
                                height: 148px;
                                border: 1px dashed #c0ccda;
                                border-radius: 5px;
                                padding: 0;
                              "
                              :src="ruleForm.img"
                              :preview-src-list="srcList"
                            >
                            </el-image>
                          </div>
                        </el-form-item>
                      </div>
                    </div>
                    <div v-if="active == 1">
                      <div>
                        <div class="text_one">
                          <span class="xing">*</span
                          ><span class="text_wen"
                            >待解决的关键问题或关键指标定量描述</span
                          >
                          (<el-popover
                            placement="bottom-start"
                            trigger="hover"
                            width="684"
                          >
                            <div
                              style="
                                padding: 30px 28px;
                                color: rgb(153, 153, 153);
                                line-height: 22px;
                              "
                            >
                              <p>示例：以xx生产线MES系统为例：</p>
                              <p>
                                （1）实现信息存储、可追溯，形成集各工序开/完工时间、工位号、操作者、原材料等信息在
                                内的生产档案。
                              </p>
                              <p>
                                （2）保证各个工位设备处于最佳工作状态（通过用户大数据做参数分析和优化，建立数据模
                                型）；
                              </p>
                              <p>
                                （3）对工艺参数的变化趋势进行直观分析，实现生产管理过程主动监测、主动预警；
                              </p>
                            </div>

                            <span class="cha_kan" slot="reference"
                              >查看案例</span
                            > </el-popover
                          >)
                        </div>
                        <div class="input_two">
                          <el-input
                            type="textarea"
                            :autosize="{ minRows: 10, maxRows: 10 }"
                            placeholder="请输入内容"
                            v-model="ruleForm.problem"
                          >
                          </el-input>
                        </div>

                        <div class="text_one">
                          <span class="xing">*</span
                          ><span class="text_wen"
                            >自定义标签(一个标签完成度为 4%,最多5个)</span
                          >
                        </div>
                        <div class="three_box">
                          <el-tag
                            :key="index"
                            v-for="(tag, index) in dynamicTags"
                            closable
                            :disable-transitions="false"
                            @close="handleClose(tag)"
                          >
                            {{ tag.name }}
                          </el-tag>
                          <el-input
                            class="input-new-tag"
                            v-if="inputVisible"
                            v-model="inputValue"
                            ref="saveTagInput"
                            size="small"
                            @keyup.enter.native="handleInputConfirm"
                            @blur="handleInputConfirm"
                          >
                          </el-input>
                          <el-button
                            v-else
                            v-show="dynamicTags.length <= 4"
                            class="button-new-tag"
                            size="small"
                            @click="showInput"
                            >+新建标签</el-button
                          >
                        </div>
                      </div>
                    </div>
                    <div v-if="active == 2">
                      <div class="text_one">
                        <span class="text_wen"
                          >自身现有的技术水平及行业一流技术水平</span
                        >
                        (<el-popover
                          placement="bottom-start"
                          trigger="hover"
                          width="684"
                        >
                          <div
                            style="
                              padding: 30px 28px;
                              color: rgb(153, 153, 153);
                              line-height: 22px;
                            "
                          >
                            <p>示例：以xx生产线MES系统为例：</p>
                            <p>
                              （1）行业水平：国外厂商，主要有西门子等；国内厂商，比较知名如用友等
                            </p>
                            <p>
                              （2）自身现有水平：公司目前使用第三方机构的MES系统，尚未组建自己的研发团队。
                            </p>
                          </div>

                          <span class="cha_kan" slot="reference"
                            >查看案例</span
                          > </el-popover
                        >)
                      </div>
                      <div class="input_two">
                        <el-input
                          type="textarea"
                          :autosize="{ minRows: 10, maxRows: 10 }"
                          placeholder="请输入内容"
                          v-model="ruleForm.tech"
                        >
                        </el-input>
                      </div>
                    </div>
                    <div v-if="active == 3">
                      <div class="text_one">
                        <span class="text_wen">主要制约因素</span>
                        (<el-popover
                          placement="bottom-start"
                          trigger="hover"
                          width="684"
                        >
                          <div
                            style="
                              padding: 30px 28px;
                              color: rgb(153, 153, 153);
                              line-height: 22px;
                            "
                          >
                            <p>示例：以xx生产线MES系统为例：</p>
                            <p>
                              （1）研发成本制约：MES系统实施难度大，行业性强，公司从头研发的资金投入成本相当高；
                            </p>
                            <p>
                              （2）时间成本制约：客户从3年前便提出信息化升级的需求，当下该需求变得更加迫切，然而MES系统研发周期长，公司自行研发无法在较短时间内满足客户的需求；
                            </p>
                            <p>
                              （3）人员制约：企业目前尚未有能够承担该系统研发的信息化方面的专家。
                            </p>
                          </div>

                          <span class="cha_kan" slot="reference"
                            >查看案例</span
                          > </el-popover
                        >)
                      </div>
                      <div class="input_two">
                        <el-input
                          type="textarea"
                          :autosize="{ minRows: 10, maxRows: 10 }"
                          placeholder="请输入内容"
                          v-model="ruleForm.factors"
                        >
                        </el-input>
                      </div>
                    </div>
                    <div v-if="active == 4">
                      <div class="text_one">
                        <span class="text_wen"
                          >已经做出的努力和尝试及付出的成本</span
                        >
                        (<el-popover
                          placement="bottom-start"
                          trigger="hover"
                          width="684"
                        >
                          <div
                            style="
                              padding: 30px 28px;
                              color: rgb(153, 153, 153);
                              line-height: 22px;
                            "
                          >
                            <p>示例：以xx生产线MES系统为例：</p>
                            <p>
                              （1）公司目前使用第三方机构的MES系统，尚未组建自己的研发团队。
                            </p>
                          </div>

                          <span class="cha_kan" slot="reference"
                            >查看案例</span
                          > </el-popover
                        >)
                      </div>
                      <div class="input_two">
                        <el-input
                          type="textarea"
                          :autosize="{ minRows: 10, maxRows: 10 }"
                          placeholder="请输入内容"
                          v-model="ruleForm.strives"
                        >
                        </el-input>
                      </div>
                    </div>
                    <div v-if="active == 5">
                      <div class="text_one">
                        <span class="text_wen">对解决方案的条件约束</span>
                        (<el-popover
                          placement="bottom-start"
                          trigger="hover"
                          width="684"
                        >
                          <div
                            style="
                              padding: 30px 28px;
                              color: rgb(153, 153, 153);
                              line-height: 22px;
                            "
                          >
                            <p>示例：以xx生产线MES系统为例：</p>
                            <p>（1）团队有一定行业敏锐度</p>
                            <p>（2）合作模式</p>
                          </div>

                          <span class="cha_kan" slot="reference"
                            >查看案例</span
                          > </el-popover
                        >)
                      </div>
                      <div class="input_two">
                        <el-input
                          type="textarea"
                          :autosize="{ minRows: 10, maxRows: 10 }"
                          placeholder="请输入内容"
                          v-model="ruleForm.condition"
                        >
                        </el-input>
                      </div>
                    </div>
                    <div v-if="active == 6">
                      <div class="text_one">
                        <span class="text_wen"
                          >需求得到满足后预期可产生的收益</span
                        >
                        (<el-popover
                          placement="bottom-start"
                          trigger="hover"
                          width="684"
                        >
                          <div
                            style="
                              padding: 30px 28px;
                              color: rgb(153, 153, 153);
                              line-height: 22px;
                            "
                          >
                            <p>示例：以xx生产线MES系统为例：</p>
                            <p>预期收益1000万以上</p>
                            <p>
                              （1）目前，公司产品生产线市场占有率仅为1%（99%的市场空间），MES系统的成功研发，将大幅度提高生产线的信息化程度，帮助企业客户提高生产效率，降低生产成本，增加了原有产品的附加值，从而有效帮助企业占领更大的市场份额；
                            </p>
                            <p>
                              （2）结合自身的工业优势，MES系统的广泛应用将帮助企业有效汇聚批量工业大数据资源，有望开发面向行业的、具有自主知识产权的MES工具集及软硬件系统平台，转型工业互联网企业。
                            </p>
                          </div>

                          <span class="cha_kan" slot="reference"
                            >查看案例</span
                          > </el-popover
                        >)
                      </div>
                      <div class="two_box">
                        <div
                          class="buttons"
                          :class="{ actives: overIndex === index }"
                          v-for="(item, index) in button_arr"
                          :key="index"
                          @click="tabClickss(item, index)"
                        >
                          {{ item.name }}
                        </div>
                      </div>
                    </div>
                    <div v-if="active == 7">
                      <div class="text_one">
                        <span class="text_wen"
                          >对需求解答人或团队的从业经历和项目经历要求</span
                        >
                        (<el-popover
                          placement="bottom-start"
                          trigger="hover"
                          width="684"
                        >
                          <div
                            style="
                              padding: 30px 28px;
                              color: rgb(153, 153, 153);
                              line-height: 22px;
                            "
                          >
                            <p>示例：以xx生产线MES系统为例：</p>
                            <p>（1）高校院所专家团队。</p>
                            <p>
                              （2）具有MES系统开发能力，过往有相关成功案例。
                            </p>
                            <p>（3）对行业有较深入研究，了解生产线工艺。</p>
                          </div>

                          <span class="cha_kan" slot="reference"
                            >查看案例</span
                          > </el-popover
                        >)
                      </div>
                      <div class="input_two">
                        <el-input
                          type="textarea"
                          :autosize="{ minRows: 10, maxRows: 10 }"
                          placeholder="请输入内容"
                          v-model="ruleForm.requirement"
                        >
                        </el-input>
                      </div>
                    </div>
                    <div v-if="active == 8">
                      <div class="text_one">
                        <span class="text_wen">需求解决方式 </span>
                        (<el-popover
                          placement="bottom-start"
                          trigger="hover"
                          width="684"
                        >
                          <div
                            style="
                              padding: 30px 28px;
                              color: rgb(153, 153, 153);
                              line-height: 22px;
                            "
                          >
                            <p>示例：以xx生产线MES系统为例：</p>
                            <p>
                              （1）共建新研发、生产实体（由于工业软件的个性化定制特点，需要建立长期合伙人的合作方式，通过技术入股或资金入股的形式进行合作）
                            </p>
                          </div>

                          <span class="cha_kan" slot="reference"
                            >查看案例</span
                          > </el-popover
                        >)
                      </div>
                      <div class="two_box">
                        <!--:class="{actives :overIndex===index}"   :class="{actives:item.isSelected}" :class="{ actives: arr.includes(index) }"-->
                        <div
                          class="buttons"
                          :class="[{ actives: arr.indexOf(index) > -1 }]"
                          v-for="(item, index) in button_arr1"
                          :key="index"
                          @click="tabClick(item, index)"
                        >
                          {{ item.name }}
                        </div>
                      </div>
                    </div>
                    <div v-if="active == 9">
                      <div class="text_one">
                        <span class="text_wen">还需要哪些关联服务需求</span>
                        (<el-popover
                          placement="bottom-start"
                          trigger="hover"
                          width="684"
                        >
                          <div
                            style="
                              padding: 30px 28px;
                              color: rgb(153, 153, 153);
                              line-height: 22px;
                            "
                          >
                            <p>示例：以xx生产线MES系统为例：</p>
                            <p>
                              （1）知识产权服务 、行业政策推送 、科技政策解读
                            </p>
                          </div>

                          <span class="cha_kan" slot="reference"
                            >查看案例</span
                          > </el-popover
                        >)
                      </div>
                      <div class="two_box">
                        <!--:class="{actives :overIndex===index}" :class="item.isSelected ? 'actives' : ''"-->
                        <div
                          class="buttons"
                          :class="[{ actives: arr1.indexOf(index) > -1 }]"
                          v-for="(item, index) in button_arr2"
                          :key="index"
                          @click="tabClick1(item, index)"
                        >
                          {{ item.name }}
                        </div>
                      </div>
                    </div>
                  </el-form>
                </section>

                <div class="footer">
                  <el-button
                    v-if="active > 0"
                    type="info"
                    icon="el-icon-arrow-left"
                    @click="prev"
                    >上一步
                  </el-button>
                  <el-button
                    v-if="active < step - 1"
                    type="primary"
                    icon="el-icon-arrow-right"
                    @click="next"
                    >下一步</el-button
                  >

                  <el-button type="success" @click="submitForm(1, 'ruleForm')"
                    >保存草稿</el-button
                  >
                  <el-button type="primary" v-if="percentage < 50" disabled
                    >提交</el-button
                  >
                  <el-button
                    type="primary"
                    v-else-if="percentage >= 50"
                    @click="submitForm(2)"
                    >提交</el-button
                  >

                  <div class="red_text">
                    <i class="el-icon-warning-outline"></i>
                    完整度大于50%方可提交
                  </div>
                </div>
                <!-- </el-tab-pane>
                </el-tabs> -->
              </div>
            </el-tab-pane>
            <el-tab-pane class="tab_title" label="" name="second">
              <div slot="label">
                <div class="tab_title_flex tab_title_flex_two">
                  普通发布模式
                </div>
              </div>
              <common></common>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </el-container>
</template>
<script>
import common from "./common.vue";
import {
  demand_adds,
  demand_draft,
  Rdraft,
  demand_title,
  demand_type,
  demand_area,
  demand_urgency,
  expertqi,
  expertjiu,
  expertshi,
} from "../../common/js/api";
import { handleCompressImg } from "@/api";
export default {
  name: "NeedJc",
  components: {
    common,
  },
  data() {
    // 设置手机号的验证规则
    const phoneRules = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入联系方式"));
      } else {
        const reg = /^1[3|4|5|6|7|8][0-9]\d{8}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的联系方式"));
        }
      }
    };
    return {
      srcList: [],
      httpurl: "http://hout.kehui.cloud/",
      show: false,
      dialogVisible: false,
      dialogImageUrl: "",
      step: 10,
      active: 0,
      percentage: 2,
      dialogVisible: false,
      dialogImageUrl: "",
      hideUploadG: false,
      dynamicTags: [], //自定义标签
      inputVisible: false,
      inputValue: "",
      lingyu: [],
      leibie: [],
      di_qv: [],
      jinji: [],
      overIndex: "",
      button_arr: [],
      button_arr1: [],
      button_arr2: [],
      ruleForm: {
        member_id: JSON.parse(localStorage.getItem("user")).id,
        title: "", //需求名称
        territory: "", //领域id
        type: "", //类别
        address: "", //需求地点
        urgency: "", //紧急程度
        funds: "", //预算经费
        company_name: "", //公司名称
        contacts: "", //联系人
        phone: JSON.parse(localStorage.getItem("user")).phone, //联系电话
        is_open: 1, //是否公开
        img: "",
        problem: "",
        tech: "", //3
        factors: "", //4
        strives: "", //5
        condition: "", //6
        profit: "", //7
        requirement: "", //8
        // solution: "", //9
        // service_type: "", //10
        draft: 2,
      },
      kong: [],
      activeName: "first",
      user: JSON.parse(localStorage.getItem("user")),
      rules: {
        title: [
          { required: true, message: "请输入需求名称", trigger: "blur" },
          // {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ],
        territory: [
          { required: true, message: "请选择需求地点", trigger: "change" },
        ],
        type: [
          {
            required: true,
            message: "请至少选择一个活动性质",
            trigger: "change",
          },
        ],
        address: [{ required: true, message: "请填写", trigger: "change" }],
        urgency: [{ required: true, message: "请填写", trigger: "change" }],
        funds: [{ required: true, message: "请填写", trigger: "change" }],
        content: [{ required: true, message: "请填写", trigger: "blur" }],
        company_name: [{ required: true, message: "请填写", trigger: "blur" }],
        contacts: [{ required: true, message: "请填写", trigger: "blur" }],
        phone: [{ required: true, validator: phoneRules, trigger: "blur" }],
        is_open: [{ required: true, message: "请填写", trigger: "blur" }],
        img: [{ required: true, message: "请上传", trigger: "blur" }],
        tag: [{ required: true, message: "请填写", trigger: "blur" }],
      },

      activeNames: "one",
      buobj: {},
      shu_jv: {},
      flag: true,
      flagtwo: true,
      flagthree: true,
      flagfour: true,
      flagfive: true,
      flagsix: true,
      flagseven: true,
      flageight: true,
      flagnine: true,
      Fproblem: true,
      flagtech: true,
      ffactors: true,
      fstrives: true,
      fcondition: true,
      foverIndex: true,
      frequirement: true,
      Selected: true,
      isSelected: true,
      activeNamees: "",
      user: JSON.parse(localStorage.getItem("user")),
      xu: [],
      solution: [],
      arr: [],
      arr1: [],
      service_type: [],
      draftId: "",
    };
  },
  computed: {
    newNum: function () {
      return this.ruleForm.title;
    },
    newNumtwo: function () {
      return this.ruleForm.territory;
    },
    newNumthree: function () {
      return this.ruleForm.type;
    },
    newNumfour: function () {
      return this.ruleForm.address;
    },
    newNumfive: function () {
      return this.ruleForm.urgency;
    },
    newNumsix: function () {
      return this.ruleForm.funds;
    },
    newNumseven: function () {
      return this.ruleForm.company_name;
    },
    newNumeight: function () {
      return this.ruleForm.contacts;
    },
    problem: function () {
      return this.ruleForm.problem;
    },
    tech: function () {
      return this.ruleForm.tech;
    },
    textareafour: function () {
      return this.ruleForm.factors;
    },
    strives: function () {
      return this.ruleForm.strives;
    },
    condition: function () {
      return this.ruleForm.condition;
    },
    requirement: function () {
      return this.ruleForm.requirement;
    },
  },
  watch: {
	  
    requirement: {
      handler(val, oldVal) {
        if (val === "") {
          this.percentage = this.percentage - 8;
          this.frequirement = true;
        } else {
          if (this.frequirement === true) {
            this.frequirement = false;
            this.percentage = this.percentage + 8;
          }
        }
        this.$emit("percentage", this.percentage);
      },
    },
    overIndex: {
      handler(val, oldVal) {
        if (val === "") {
          this.percentage = this.percentage - 5;
          this.foverIndex = true;
        } else {
          if (this.foverIndex === true) {
            this.foverIndex = false;
            this.percentage = this.percentage + 5;
          }
        }
        this.$emit("percentage", this.percentage);
      },
    },
    condition: {
      handler(val, oldVal) {
        if (val === "") {
          this.percentage = this.percentage - 8;
          this.fcondition = true;
        } else {
          if (this.fcondition === true) {
            this.fcondition = false;
            this.percentage = this.percentage + 8;
          }
        }
        this.$emit("percentage", this.percentage);
      },
    },
    strives: {
      handler(val, oldVal) {
        if (val === "") {
          this.percentage = this.percentage - 8;
          this.fstrives = true;
        } else {
          if (this.fstrives === true) {
            this.fstrives = false;
            this.percentage = this.percentage + 8;
          }
        }
        this.$emit("percentage", this.percentage);
      },
    },
    textareafour: {
      handler(val, oldVal) {
        if (val === "") {
          this.percentage = this.percentage - 8;
          this.ffactors = true;
        } else {
          if (this.ffactors === true) {
            this.ffactors = false;
            this.percentage = this.percentage + 8;
          }
        }
        this.$emit("percentage", this.percentage);
      },
    },
    tech: {
      handler(val, oldVal) {
        if (val === "") {
          this.percentage = this.percentage - 10;
          this.flagtech = true;
        } else {
          if (this.flagtech === true) {
            this.flagtech = false;
            this.percentage = this.percentage + 10;
          }
        }
        this.$emit("percentage", this.percentage);
      },
    },
    problem: {
      handler(val, oldVal) {
        if (val === "") {
          this.percentage = this.percentage - 10;
          this.Fproblem = true;
        } else {
          if (this.Fproblem === true) {
            this.Fproblem = false;
            this.percentage = this.percentage + 10;
          }
        }
        this.$emit("percentage", this.percentage);
      },
    },
    newNumeight: {
      handler(val, oldVal) {
        if (val === "") {
          this.percentage = this.percentage - 1;
          this.flageight = true;
        } else {
          if (this.flageight === true) {
            this.flageight = false;
            this.percentage = this.percentage + 1;
          }
        }
        this.$emit("percentage", this.percentage);
      },
      deep: true,
    },
    newNumseven: {
      handler(val, oldVal) {
        if (val === "") {
          this.percentage = this.percentage - 1;
          this.flagseven = true;
        } else {
          if (this.flagseven === true) {
            this.flagseven = false;
            this.percentage = this.percentage + 1;
          }
        }
        this.$emit("percentage", this.percentage);
      },
      deep: true,
    },
    newNumsix: {
      handler(val, oldVal) {
        if (val === "") {
          this.percentage = this.percentage - 2;
          this.flagsix = true;
        } else {
          if (this.flagsix === true) {
            this.flagsix = false;
            this.percentage = this.percentage + 2;
          }
        }
        this.$emit("percentage", this.percentage);
      },
      deep: true,
    },
    newNumfive: {
      handler(val, oldVal) {
        if (val === "") {
          this.percentage = this.percentage - 1;
          this.flagfive = true;
        } else {
          if (this.flagfive === true) {
            this.flagfive = false;
            this.percentage = this.percentage + 1;
          }
        }
        this.$emit("percentage", this.percentage);
      },
      deep: true,
    },
    newNumfour: {
      handler(val, oldVal) {
        if (val === "") {
          this.percentage = this.percentage - 1;
          this.flagfour = true;
        } else {
          if (this.flagfour === true) {
            this.flagfour = false;
            this.percentage = this.percentage + 1;
          }
        }
        this.$emit("percentage", this.percentage);
      },
      deep: true,
    },
    newNumthree: {
      handler(val, oldVal) {
        if (val === "") {
          this.percentage = this.percentage - 1;
          this.flagthree = true;
        } else {
          if (this.flagthree === true) {
            this.flagthree = false;
            this.percentage = this.percentage + 1;
          }
        }
        this.$emit("percentage", this.percentage);
      },
      deep: true,
    },
    newNumtwo: {
      handler(val, oldVal) {
        if (val === "") {
          this.percentage = this.percentage - 2;
          this.flagtwo = true;
        } else {
          if (this.flagtwo === true) {
            this.flagtwo = false;
            this.percentage = this.percentage + 2;
          }
        }
        this.$emit("percentage", this.percentage);
      },
      deep: true,
    },
    newNum: {
      handler(val, oldVal) {
        if (val === "") {
          this.percentage = this.percentage - 2;
          this.flag = true;
        } else {
          if (this.flag === true) {
            this.flag = false;
            this.percentage = this.percentage + 2;
          }
        }
        this.$emit("percentage", this.percentage);
      },
      deep: true,
    },
  },
  created() {
	  if(!localStorage.getItem("user")){
		  this.$router.push({
		    path: "/login",
		  });
	  }
    this.ling_yu();
    this.lei_bie();
    this.di_qvs();
    this.jin_ji();
    this.type_fei();
    this.type_fei1();
    this.type_fei2();
    this.getDraft();
  },

  methods: {
    // 草稿
    getDraft() {
      Rdraft({ member_id: JSON.parse(localStorage.getItem("user")).id })
        .then((res) => {
          // console.log(res);
          if (res.data) {
            this.$confirm("上次未完成, 是否继续?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.draftId = res.data.id;
                this.ruleForm = res.data;
                if (this.ruleForm.img) {
                  this.show = true;
                  this.hideUploadG = true;
                  this.srcList.push(this.httpurl + this.ruleForm.img);
                  this.ruleForm.img = this.httpurl + this.ruleForm.img;
                }

                if (res.data.type) {
                  this.ruleForm.type = res.data.type.id;
                }
                if (res.data.urgency) {
                  this.ruleForm.urgency = res.data.urgency.id;
                }
                if (res.data.profit) {
                  for (let i = 0; i < this.button_arr.length; i++) {
                    if (this.button_arr[i].id == res.data.profit) {
                      this.ruleForm.profit = res.data.profit;
                      this.overIndex = i;
                    }
                  }
                }
                if (res.data.solutions) {
                  // this.solution = res.data.solutions;
                  for (let i = 0; i < this.button_arr1.length; i++) {
                    //  console.log(i);
                    for (let j = 0; j < res.data.solutions.length; j++) {
                      if (
                        this.button_arr1[i].name == res.data.solutions[j].name
                      ) {
                        this.arr.push(i);
                        this.solution.push({
                          name: this.button_arr1[i].name,
                          id: this.button_arr1[i].id,
                        });
                      }
                    }
                  }
                }
                if (res.data.servicetypes) {
                  for (let i = 0; i < this.button_arr2.length; i++) {
                    for (let j = 0; j < res.data.servicetypes.length; j++) {
                      if (
                        this.button_arr2[i].name ==
                        res.data.servicetypes[j].name
                      ) {
                        this.arr1.push(i);
                        this.service_type.push({
                          name: this.button_arr2[i].name,
                          id: this.button_arr2[i].id,
                        });
                      }
                    }
                  }
                }
                if (res.data.label) {
                  this.dynamicTags = res.data.label;
                }
              })
              .catch(() => {});
          }
        })
        .catch(() => {});
    },
    goActve(e) {
      this.active = e;
    },
    tabClickss(item, index) {
      //点击
      this.ruleForm.profit = item.id;
      this.overIndex = index;
    },
    tabClick(item, i) {
      //点击
      //     if (this.arr.includes(i)) {
      //       this.arr = this.arr.filter(function (ele) {
      //         return ele != i;
      //       });
      //       this.solution = this.solution.filter(function (ele) {
      //         return ele != item.name;
      //       });
      //     } else {
      //       this.arr.push(i);
      //       this.solution.push({ name: item.name, id: item.id });

      //     }
      //     console.log(this.solution);
      // if (this.solution.length==1) {
      //           this.percentage = this.percentage + 5;
      //        }
      //        if (this.solution.length == 0) {
      //         this.percentage = this.percentage - 5;

      //       }
      if (this.arr.indexOf(i) === -1) {
        this.arr.push(i);
        this.solution.push({ name: item.name, id: item.id });
        if (this.solution.length == 1) {
          this.percentage = this.percentage + 5;
        }
      } else {
        let s = this.arr.indexOf(i);
        let t = this.solution.indexOf(item.name);
        this.arr.splice(s, 1);
        this.solution.splice(t, 1);
        if (this.solution.length == 0) {
          this.percentage = this.percentage - 5;
        }
      }
    },
    tabClick1(item, i) {
      //点击

      if (this.arr1.indexOf(i) === -1) {
        this.arr1.push(i);
        this.service_type.push({ name: item.name, id: item.id });
        if (this.service_type.length == 1) {
          this.percentage = this.percentage + 5;
        }
      } else {
        let s = this.arr1.indexOf(i);
        let t = this.service_type.indexOf(item.name);
        this.arr1.splice(s, 1);
        this.service_type.splice(t, 1);
        if (this.service_type.length == 0) {
          this.percentage = this.percentage - 5;
        }
      }
    },
    fnI(file, fileList) {
      this.hideUploadG = fileList.length >= 1;
      handleCompressImg(file.raw).then((result) => {
        this.Up(result, 1);
      });
    },
    //将图片地址上传
    async Up(file) {
      let formData = new FormData();
      formData.append("file", file);

      await this.$axios
        .post("http://hout.kehui.cloud/api/demand/uploadImg", formData, {
          headers: { "Content-Type": '"multipart/form-data"' },
        })
        .then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            // this.$message.success("上传成功");
            console.log(res);
            this.ruleForm.img = res.data.data;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {});
    },
    handleRemoveG(file, fileList) {
      this.hideUploadG = fileList.length >= 1;
    },
    handlePictureCardPreviewG(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    //   下一步
    next: function () {
      if (this.active++ > 9) this.active = 0;
    },
    // 上一步
    prev: function () {
      if (--this.active < 0) this.active = 0;
    },

    submitForm(i) {
      // console.log(i);

      if (i == 1) {
        this.ruleForm.draft = 1;
        if (this.draftId) {
          this.draftId = this.draftId;
          demand_draft({
            data: {
              member_id: JSON.parse(localStorage.getItem("user")).id,
              title: this.ruleForm.title, //需求名称
              territory: this.ruleForm.territory, //领域id
              type: this.ruleForm.type, //类别
              address: this.ruleForm.address, //需求地点
              urgency: this.ruleForm.urgency, //紧急程度
              funds: this.ruleForm.funds, //预算经费
              company_name: this.ruleForm.company_name, //公司名称
              contacts: this.ruleForm.contacts, //联系人
              phone: this.ruleForm.phone, //联系电话
              img: this.ruleForm.img, //图片
              is_open: 1, //是否公开
              problem: this.ruleForm.problem,
              tech: this.ruleForm.tech, //3
              factors: this.ruleForm.factors, //4
              strives: this.ruleForm.strives, //5
              condition: this.ruleForm.condition, //6
              profit: this.ruleForm.profit, //7
              requirement: this.ruleForm.requirement, //8
              // solution: "", //9
              // service_type: "", //10
              draft: 1,
            },
            tag: this.dynamicTags,
            service_type: this.service_type,
            solution: this.solution,
            id: this.draftId,
          })
            .then((res) => {
              if (res.code == 200) {
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                this.$router.push({
                  path: "/need",
                });
              } else {
                this.$message({
                  message: res.msg,
                  type: "error",
                });
              }
            })
            .catch((err) => {});
        } else {
          this.draftId = "";
          demand_draft({
            data: this.ruleForm,
            tag: this.dynamicTags,
            service_type: this.service_type,
            solution: this.solution,
            id: this.draftId,
          })
            .then((res) => {
              if (res.code == 200) {
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                this.$router.push({
                  path: "/need",
                });
              } else {
                this.$message({
                  message: res.msg,
                  type: "error",
                });
              }
            })
            .catch((err) => {});
        }
      } else {
        this.ruleForm.draft = 2;
        if (this.draftId) {
          demand_adds({
            // data: this.ruleForm,
            data: {
              member_id: JSON.parse(localStorage.getItem("user")).id,
              title: this.ruleForm.title, //需求名称
              territory: this.ruleForm.territory, //领域id
              type: this.ruleForm.type, //类别
              address: this.ruleForm.address, //需求地点
              urgency: this.ruleForm.urgency, //紧急程度
              funds: this.ruleForm.funds, //预算经费
              company_name: this.ruleForm.company_name, //公司名称
              contacts: this.ruleForm.contacts, //联系人
              phone: this.ruleForm.phone, //联系电话
              img: this.ruleForm.img, //图片
              is_open: 1, //是否公开
              problem: this.ruleForm.problem,
              tech: this.ruleForm.tech, //3
              factors: this.ruleForm.factors, //4
              strives: this.ruleForm.strives, //5
              condition: this.ruleForm.condition, //6
              profit: this.ruleForm.profit, //7
              requirement: this.ruleForm.requirement, //8
              draft: 2,
            },
            tag: this.dynamicTags,
            service_type: this.service_type,
            solution: this.solution,
            id: this.draftId,
          })
            .then((res) => {
              if (res.code == 200) {
                this.$confirm(
                  "提交成功，请等待管理员审核，若须加急审核，请拨打客服电话。",
                  "提示",
                  {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                  }
                )
                  .then(() => {
                    this.$router.push({
                      path: "/need",
                    });
                  })
                  .catch(() => {
                    this.$router.push({
                      path: "/need",
                    });
                  });
              } else {
                this.$message({
                  message: res.msg,
                  type: "error",
                });
              }
            })
            .catch((err) => {});
        } else {
          demand_adds({
            data: this.ruleForm,
            tag: this.dynamicTags,
            service_type: this.service_type,
            solution: this.solution,
            id: "",
          })
            .then((res) => {
              if (res.code == 200) {
                this.$confirm(
                  "提交成功，请等待管理员审核，若须加急审核，请拨打客服电话。",
                  "提示",
                  {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                  }
                )
                  .then(() => {
                    this.$router.push({
                      path: "/need",
                    });
                  })
                  .catch(() => {
                    this.$router.push({
                      path: "/need",
                    });
                  });
              } else {
                this.$message({
                  message: res.msg,
                  type: "error",
                });
              }
            })
            .catch((err) => {});
        }
      }
      // console.log(this.ruleForm, this.service_type, this.solution);
    },

    type_fei2() {
      expertshi({})
        .then((res) => {
          for (var i = 0; i < res.data.length; i++) {
            Object.assign(res.data[i], { isSelected: false });
          }
          this.button_arr2 = res.data;
        })
        .catch((err) => {});
    },
    type_fei1() {
      expertjiu({})
        .then((res) => {
          for (var i = 0; i < res.data.length; i++) {
            Object.assign(res.data[i], { isSelected: false });
          }
          this.button_arr1 = res.data;
        })
        .catch((err) => {});
    },
    // 收益
    type_fei() {
      expertqi({})
        .then((res) => {
          // console.log(res.data);
          this.button_arr = res.data;
        })
        .catch((err) => {});
    },
    //紧急程度
    jin_ji() {
      demand_urgency({})
        .then((res) => {
          this.jinji = res.data;
        })
        .catch((err) => {});
    },
    //地区
    di_qvs() {
      demand_area({})
        .then((res) => {
          this.di_qv = res.data;
        })
        .catch((err) => {});
    },
    //类别
    lei_bie() {
      demand_type({})
        .then((res) => {
          this.leibie = res.data;
        })
        .catch((err) => {});
    },
    //领域
    ling_yu() {
      demand_title({})
        .then((res) => {
          this.lingyu = res.data;
        })
        .catch((err) => {});
    },

    // 标签
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
      if (this.dynamicTags.length >= 0) {
        this.percentage = this.percentage - 4;
      }
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push({ name: inputValue });
        if (this.dynamicTags.length <= 5) {
          this.percentage = this.percentage + 4;
        }
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
  },
};
</script>
<style scoped lang="less">
/*上传头像*/

/deep/.el-step.is-simple .el-step__title {
  width: 60px !important;
}
/deep/.el-step.is-simple:not(:last-of-type) .el-step__title {
  max-width: 75%;
}
/deep/.el-step__title.is-process {
  color: #fff;
  background-color: #3892eb;
}
/deep/.el-steps--simple {
  padding-bottom: 20px !important;
}
/deep/.el-textarea__inner {
  resize: none;
}
.demand_one {
  display: flex;
  align-items: center;
  div {
    width: 100%;
    padding: 0 20px;
  }
}
.footer {
  margin: 30px 0;
  text-align: center;
  .red_text {
    margin: 15px auto;
    width: 300px;
    color: #fb0016;
    font-size: 22px;
  }
}
/deep/input::-webkit-outer-spin-button,
/deep/input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
/deep/input[type="number"] {
  -moz-appearance: textfield;
}
/deep/.demo-ruleForm {
  margin: 0 200px !important;
  width: 1200px;
}
/deep/.demo-ruleForm {
  margin: 0 300px !important;
  width: 1200px;
  .el-input {
    width: 519px;
  }
}
/* 笔记本电脑的字体大小 */
@media screen and (min-width: 768px) and (max-width: 1070px) {
  /deep/.el-tabs__item {
    height: unset;
    padding: 0px -3px !important;
  }
  .num_one {
    border-radius: 100% !important;
    text-align: center;
    line-height: 55px !important;
    width: 60px !important;
    height: 55px !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    color: rgba(56, 146, 235, 100);
    border: 1px solid rgba(56, 146, 235, 100);
  }
}
/* 台式电脑显示器屏幕字体大小 */
@media screen and (min-width: 1200px) {
}
/deep/.el-form-item__label {
  height: 50px;
  line-height: 50px;
}
/deep/ .el-input__inner {
  height: 50px;
  line-height: 50px;
}
/deep/.hide .el-upload--picture-card {
  display: none;
}

.tab_title_flex_one {
  margin-left: 480px;
}
.tab_title_flex {
  margin: 30px;
}
.bu_zhou_ten {
  margin: 0px 30px;
}
.tab_img {
  width: 24px;
  height: 24px;
  margin-top: -10px;
  margin-left: 7px;
  img {
    width: 24px;
    height: 24px;
  }
}

.tab_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/deep/ .el-tabs__active-bar {
  background-color: unset;
}
/deep/.el-tabs__item {
  height: unset;
  padding: 0px 13px !important;
}

.active {
  background-color: rgba(56, 146, 235, 100);
  color: #fff !important;
}

.num_one {
  border-radius: 100%;
  text-align: center;
  line-height: 58px;
  width: 60px;
  height: 58px;
  font-size: 30px;
  font-weight: 600;
  color: rgba(56, 146, 235, 100);
  border: 1px solid rgba(56, 146, 235, 100);
}

.bai_fen {
  color: #ff0707;
  font-size: 20px;
}

.wan {
  padding: 0px 15px;
}

.dang_text {
  padding-right: 15px;
  color: rgba(16, 16, 16, 100);
  font-size: 18px;
}
/deep/.el-progress-bar {
  width: unset;
}

/deep/ .el-progress-bar__outer {
  width: 400px;
}

/deep/ .bu_zou {
  display: flex;
  margin: 20px 30px;
}

/deep/ .el-steps--simple {
  padding: 0px;
  background-color: #fff;
}

/deep/ .el-step.is-simple .el-step__title {
  text-align: center;
  font-size: 28px;
  width: 120px;
  height: 60px;
  line-height: 60px;
  border: 1px solid #3892eb;
  /*background-color:#3892EB ;*/
  border-radius: 50%;
}

/deep/ .el-step__icon.is-text {
  border: none;
}

/deep/ .el-tabs__nav-wrap::after {
  background-color: #fff;
}

/deep/ .el-radio-group {
  width: 519px;
}

/deep/ .icones {
  font-size: 48px;
}

.el-container {
  background-color: #f8f9fa;
  width: 100%;
}

.box {
  width: 1200px;
  margin: 0 auto;
  .box_two {
    margin: 0 auto;
    width: 1200px;
    background-color: #fff;
  }
  .title_flex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .text {
      font-size: 28px;
      color: #3892eb;
      font-weight: 600;
    }
    .line {
      margin: 50px;
      width: 60px;
      height: 1px;
      background-color: #3892eb;
    }
  }
}

.text_one {
  margin: 30px;
  font-size: 18px;
  .cha_kan {
    color: #2a71d2;
  }
}
.two_box {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 165px;
  .buttons {
    background: #ecf2fd;
    margin-right: 20px;
    margin-bottom: 20px;
    width: auto;
    text-align: center;
    padding: 0px 30px;
    height: 50px;
    line-height: 50px;
  }
}
.actives {
  background-color: #2a71d2 !important;
  color: #fff !important;
}
@media screen and (max-width: 1200px) {
		/deep/.el-container .box{
			width: 98%;
		}
		.box{
			width: 98%;
		}
		.box .box_two{
			width: 100%;
		}
		.box .title_flex .text{
			font-size: 2.2rem;
		}
		/deep/.el-container .box .title{
			font-size: 2.4rem;
		}
		.tab_title_flex_one{
			margin-left: 10rem;
			font-size: 1.7rem;
		}
		.tab_title_flex{
			font-size: 1.7rem;
		}
		/deep/.el-step.is-simple{
			flex-shrink: 0;
			flex-basis:10% !important;
		}
		/deep/.el-step.is-simple .el-step__head{
			display: none;
		}
		.dang_text{
			font-size: 1.4rem;
		}
		.bu_zou span{
			font-size: 1.4rem;
		}
		.demand_one{
			flex-wrap: wrap;
		}
		/deep/.el-form-item__label{
			font-size: 1.6rem;
			width: 9rem !important;
		}
		/deep/.el-form-item__content{
			margin-left: 9rem !important;
		}
		/deep/.el-input__inner{
			font-size: 1.5rem;
			height: 3rem;
		}
		/deep/.el-form-item__error{
			font-size: 1.4rem;
		}
		/deep/.el-radio{
			width: auto !important;
		}
		/deep/.el-radio__label{
			font-size: 1.4rem;
			
		}
		/deep/.el-radio__inner{
			width: 1.5rem;
			height: 1.5rem;
		}
		.demand_one div{
			padding-bottom: 1.5rem;
		}
		/deep/.el-button{
			font-size: 1.6rem;
		}
		.footer .red_text{
			font-size: 1.3rem;
			width: auto;
		}
		/deep/.demo-ruleForms{
			margin: 0 !important;
			width: 98%;
		}
		/deep/.el-textarea__inner{
			font-size: 1.4rem;
		}
		.text_one{
			font-size: 1.6rem;
		}
		.two_box .buttons{
			font-size: 1.4rem;
		}
	}
</style>