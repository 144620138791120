<template>
    <div>
         
              <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="100px"
                class="demo-ruleForms"
              >
                <el-form-item label="需求名称" prop="title">
                  <el-input
                    v-model="ruleForm.title"
                    placeholder="请填写需求名称"
                  ></el-input>
                </el-form-item>

                <el-form-item label="所属领域" prop="territory">
                  <el-select
                    v-model="ruleForm.territory"
                    placeholder="请选择领域"
                  >
                    <el-option
                      v-for="item in lingyu"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="需求类别" prop="type">
                  <el-radio-group v-model="ruleForm.type">
                    <el-radio
                      v-for="(item, index) in leibie"
                      :key="index"
                      :label="item.id"
                      >{{ item.name }}</el-radio
                    >
                  </el-radio-group>
                </el-form-item>

                <el-form-item label="需求地点" prop="address">
                  <el-select
                    v-model="ruleForm.address"
                    placeholder="请选择需求地点"
                  >
                    <el-option
                      v-for="item in di_qv"
                      :key="item.id"
                      :label="item.title"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>

                <!--紧急程度-->
                <el-form-item label="紧急程度" prop="urgency">
                  <el-radio-group v-model="ruleForm.urgency">
                    <el-radio
                      v-for="(item, index) in jinji"
                      :key="index"
                      :label="item.id"
                      >{{ item.name }}</el-radio
                    >
                  </el-radio-group>
                </el-form-item>

                <el-form-item label="预算经费" prop="funds">
                  <el-input
                    @mousewheel.native.prevent
                    type="number"
                    v-model="ruleForm.funds"
                    placeholder="请填写预算经费"
                  ></el-input>
                </el-form-item>

                <el-form-item label="需求详情" prop="content">
                  <el-input
                    placeholder="请输入需求详情"
                    type="textarea"
                    v-model="ruleForm.content"
                  ></el-input>
                </el-form-item>

                <el-form-item label="自定义标签"  >
                  <el-tag
                    :key="tag"
                    v-for="tag in dynamicTags"
                    closable
                    :disable-transitions="false"
                    @close="handleClose(tag)"
                  >
                    {{ tag }}
                  </el-tag>
                  <el-input
                    class="input-new-tag"
                    v-if="inputVisible"
                    v-model="inputValue"
                    ref="saveTagInput"
                    size="small"
                    @keyup.enter.native="handleInputConfirm"
                    @blur="handleInputConfirm"
                  >
                  </el-input>
                  <el-button
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="showInput"
                    >+新建标签</el-button
                  >
                </el-form-item>

                <el-form-item label="公司名称" prop="company_name">
                  <el-input
                    v-model="ruleForm.company_name"
                    placeholder="请填写公司名称"
                  ></el-input>
                </el-form-item>

                <el-form-item label="联系人" prop="contacts">
                  <el-input
                    v-model="ruleForm.contacts"
                    placeholder="请填写联系人"
                  ></el-input>
                </el-form-item>

                <el-form-item label="联系电话" prop="phone">
                  <el-input
                    v-model="ruleForm.phone"
                    maxlength="11"
                    placeholder="请填写联系电话"
                  ></el-input>
                </el-form-item>

                <!--紧急程度-->
                <el-form-item label="是否公开" prop="is_open">
                  <el-radio-group v-model="ruleForm.is_open">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="2">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="上传图片" class="header" prop="img">
                  <el-upload
                    :on-change="fn"
                    action=""
                    list-type="picture-card"
                    :on-preview="handlePictureCardPreviewG"
                    :on-remove="handleRemoveG"
                    :auto-upload="false"
                    accept="image/*"
                    :class="{ hide: hideUploadG }"
                    class="avatar-uploader"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisible">
                    <img width="100%" :src="dialogImageUrl" alt="" />
                  </el-dialog>
                </el-form-item>

                <el-form-item>
                  <el-button type="primary" @click="resetForm('ruleForm')"
                    >取消</el-button
                  >
                  <el-button @click="resetForms('ruleForm')">提交</el-button>
                </el-form-item>
              </el-form>
    </div>
</template>
<script>
import { handleCompressImg } from "../../../api";
 
import {
  demand_add,
  demand_title,
  demand_type,
  demand_area,
  demand_urgency,
} from "../../common/js/api"; 

export default {
  name: "NeedJc",

  data() {
    // 设置手机号的验证规则
    const phoneRules = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入联系方式"));
      } else {
        const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的联系方式"));
        }
      }
    };
    return {
      step: 10,
      active: 0,
      percentage: 0,
      dialogVisible: false,
      dialogImageUrl: "",
      hideUploadG: false,
      tags: [], //自定义标签
      dynamicTags: [],
      inputVisible: false,
      inputValue: "",
      lingyu: [],
      leibie: [],
      di_qv: [],
      jinji: [],
       ruleForm: {
        member_id: JSON.parse(localStorage.getItem("user")).id,
        title: "", //需求名称
        territory: "", //领域id
        type: "", //类别
        address: "", //需求地点
        urgency: "", //紧急程度
        funds: "", //预算经费
        content: "", //需求详情
        company_name: "", //公司名称
        contacts: "", //联系人
        phone: JSON.parse(localStorage.getItem("user")).phone, //联系电话
        is_open: 1, //是否公开
        img: "",
      },
      
      kong: [],
      activeName: "first",
      user: JSON.parse(localStorage.getItem("user")),
      rules: {
        title: [
          { required: true, message: "请输入需求名称", trigger: "blur" },
          // {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ],
        territory: [
          { required: true, message: "请选择需求地点", trigger: "change" },
        ],
        type: [
          {
            required: true,
            message: "请至少选择一个活动性质",
            trigger: "change",
          },
        ],
        address: [{ required: true, message: "请填写", trigger: "change" }],
        urgency: [{ required: true, message: "请填写", trigger: "change" }],
        funds: [{ required: true, message: "请填写", trigger: "change" }],
        content: [{ required: true, message: "请填写", trigger: "blur" }],
        company_name: [{ required: true, message: "请填写", trigger: "blur" }],
        contacts: [{ required: true, message: "请填写", trigger: "blur" }],
        phone: [{ required: true, validator: phoneRules, trigger: "blur" }],
        is_open: [{ required: true, message: "请填写", trigger: "blur" }],
        img: [{ required: true, message: "请上传", trigger: "blur" }],
        tag: [{ required: true, message: "请填写", trigger: "blur" }],
      },
       
      activeNames: "one",
        msgFormSon: 0,
      buobj: {},
      shu_jv: {},
    };
  },
  created() {
    this.ling_yu();
    this.lei_bie();
    this.di_qvs();
    this.jin_ji();
  },
  watch: {
    msgFormSon: {
      handler(val, oldVal) {
        if (val != 0) {
          this.percentage = this.msgFormSon;
        } else if (val === 0) {
          this.percentage = this.percentage - oldVal;
        }
      },
      deep: true,
    },
  },
  methods: {
    fa_bu() {
      demand_add({
        data: this.ruleForm,
        tag: this.tags,
      })
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.$router.push({
              path: "/need",
            });
          } else {
            this.$message({
              message: res.msg,
              type: "success",
            });
          }
        })
        .catch((err) => {});
    },
    arrson(data) {
      //第二步数组
      console.log(data);
      this.tag = data;
    },
    ruleson(data) {
      //第一步
      this.shu_jv = Object.assign(this.buobj, data);
      // console.log(data)
    },
    //two
    sons(data) {
      this.percentage = data;
    },
    submitForm() {},
    //one
    getson(data) {
      this.activeNamees = data;
    },
    getMsgFormSon(data) {
      this.msgFormSon = data;
    },
    fn(file, fileList) {
      this.hideUploadG = fileList.length >= 1;
      handleCompressImg(file.raw).then((result) => {
        this.UploadImg(result, 1);
      });
    },
    //将图片地址上传
    async UploadImg(file) {
      let formData = new FormData();
      formData.append("file", file);
      await this.$axios
        .post("http://hout.kehui.cloud/api/demand/uploadImg", formData, {
          headers: { "Content-Type": '"multipart/form-data"' },
        })
        .then((res) => {
          console.log(res.data.data);
          this.ruleForm.img = res.data.data;
        });
    },
    handleRemoveG(file, fileList) {
      this.hideUploadG = fileList.length >= 1;
    },
    handlePictureCardPreviewG(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    //提交
    resetForms(formName) {
      var arr = [];
      if (this.dynamicTags.length != 0) {
        for (var i = 0; i < this.dynamicTags.length; i++) {
          arr.push({
            name: this.dynamicTags[i],
          });
        }
      }
      this.tags = arr;

      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.fa_bu();
        } else {
          return false;
        }
      });
    },
    //紧急程度
    jin_ji() {
      demand_urgency({})
        .then((res) => {
          this.jinji = res.data;
        })
        .catch((err) => {});
    },
    //地区
    di_qvs() {
      demand_area({})
        .then((res) => {
          this.di_qv = res.data;
        })
        .catch((err) => {});
    },
    //类别
    lei_bie() {
      demand_type({})
        .then((res) => {
          this.leibie = res.data;
        })
        .catch((err) => {});
    },
    //领域
    ling_yu() {
      demand_title({})
        .then((res) => {
          this.lingyu = res.data;
        })
        .catch((err) => {});
    },

 
 
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
  },
  
  
};
</script>
<style scoped lang="less">
 
/deep/input::-webkit-outer-spin-button,
/deep/input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
/deep/input[type="number"] {
  -moz-appearance: textfield;
}
/deep/.demo-ruleForm {
  margin: 0 200px !important;
  width: 1200px;
}
/deep/.demo-ruleForms {
  margin: 0 300px !important;
  width: 1200px;
  .el-input {
    width: 519px;
  }
}
/* 笔记本电脑的字体大小 */
@media screen and (min-width: 768px) and (max-width: 1070px) {
  /deep/.el-tabs__item {
    height: unset;
    padding: 0px -3px !important;
  }
  .num_one {
    border-radius: 100% !important;
    text-align: center;
    line-height: 55px !important;
    width: 60px !important;
    height: 55px !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    color: rgba(56, 146, 235, 100);
    border: 1px solid rgba(56, 146, 235, 100);
  }
}
/* 台式电脑显示器屏幕字体大小 */
@media screen and (min-width: 1200px) {
}
/deep/.el-form-item__label {
  height: 50px;
  line-height: 50px;
}
/deep/ .el-input__inner {
  height: 50px;
  line-height: 50px;
}
/deep/.hide .el-upload--picture-card {
  display: none;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.tab_title_flex_one {
  margin-left: 480px;
}
.tab_title_flex {
  margin: 30px;
}
.bu_zhou_ten {
  margin: 0px 30px;
}
.tab_img {
  width: 24px;
  height: 24px;
  margin-top: -10px;
  margin-left: 7px;
  img {
    width: 24px;
    height: 24px;
  }
}

.tab_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/deep/ .el-tabs__active-bar {
  background-color: unset;
}
/deep/.el-tabs__item {
  height: unset;
  padding: 0px 13px !important;
}

.active {
  background-color: rgba(56, 146, 235, 100);
  color: #fff !important;
}

.num_one {
  border-radius: 100%;
  text-align: center;
  line-height: 58px;
  width: 60px;
  height: 58px;
  font-size: 30px;
  font-weight: 600;
  color: rgba(56, 146, 235, 100);
  border: 1px solid rgba(56, 146, 235, 100);
}

.bai_fen {
  color: #ff0707;
  font-size: 20px;
}

.wan {
  padding: 0px 15px;
}

.dang_text {
  padding-right: 15px;
  color: rgba(16, 16, 16, 100);
  font-size: 18px;
}
/deep/.el-progress-bar {
  width: unset;
}
/deep/ .el-progress__text {
  /*margin-left: 7px;*/
}

/deep/ .el-progress-bar__outer {
  width: 400px;
}

/deep/ .bu_zou {
  display: flex;
  margin: 20px 30px;
}

/deep/ .el-steps--simple {
  padding: 0px;
  background-color: #fff;
}

/deep/ .el-step.is-simple .el-step__title {
  text-align: center;
  font-size: 28px;
  width: 120px;
  height: 60px;
  line-height: 60px;
  border: 1px solid #3892eb;
  /*background-color:#3892EB ;*/
  border-radius: 50%;
}

/deep/ .el-step__icon.is-text {
  border: none;
}

.el-textarea {
  position: relative;
  display: inline-block;
  width: 519px;
  vertical-align: bottom;
  font-size: 0.116667rem;
  
}

.span_bag {
  text-align: center;
  margin-left: 30px;
  display: inline-block;
  width: 130px;
  height: 38px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 100);
  color: rgba(105, 105, 105, 100);
  border: 1px solid rgba(229, 231, 236, 100);
  font-size: 14px;
}

/deep/ .el-tabs__nav-wrap::after {
  background-color: #fff;
}

/deep/ .el-radio-group {
  width: 519px;
}

/deep/ .icones {
  font-size: 48px;
}

.el-container {
  background-color: #f8f9fa;
  width: 100%;
}

.box {
  width: 1200px;
  margin: 0 auto;
  .box_two {
    margin: 0 auto;
    width: 1200px;
    background-color: #fff;
  }
  .title_flex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .text {
      font-size: 28px;
      color: #3892eb;
      font-weight: 600;
    }
    .line {
      margin: 100px;
      width: 60px;
      height: 1px;
      background-color: #3892eb;
    }
  }
}

.title_flex_two {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .text {
    color: rgba(0, 5, 10, 100);
    font-size: 18px;
    font-weight: 600;
  }
  .line {
    margin: 60px;
    width: 60px;
    /*height: 1px;*/
    /*background-color: #3892EB ;*/
  }
}
</style>