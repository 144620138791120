<template>
    <div>
        <nav-bar ></nav-bar>
        <need-fb class="nei" ></need-fb>
    </div>
</template>
<script>
    import NavBar from "../../components/common/faNavBar";
    // import NeedFb from "./base/NeedFb";
    import NeedFb from "./base/fb";
    export default {
        name: "Needjc",
        components:{
            NavBar,
            NeedFb
        }
    }
</script>

<style scoped lang="less">
    .nei{
        margin-top: 6px;
    }
</style>