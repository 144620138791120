<template>
  <el-container>
    <div class="box">
      <div class="img_box">
       <a href=""  class="logo"><img :src="urlImg +logo"  /></a>
       
      </div>
      <div class="title">需求发布全流程信息系统</div>
      <div></div>
    </div>
  </el-container>
</template>

<script>
import { Logo } from "@/common/js/api";
import { urlImg } from "../../common/js/url";
export default {
  name: "faNavBar",
  data() {
    return {
     logo:'',
      urlImg:''
    };
  },
  created() {
    this.getLogo();
   
    this.urlImg = urlImg();
   },
  methods: {
    // 获取logo
    getLogo() {
      Logo({})
        .then((res) => {
          this.logo = res.data.logo;
        })
        .catch(() => {});
    },
  },
  components: {},
};
</script>

<style scoped lang="less">
.el-container {
  z-index: 999999;
  width: 100%;
  height: 100px;
  line-height: 20px;
  text-align: center;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4) !important;
  border: 1px solid rgba(255, 255, 255, 100);
  .box {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      color: rgba(16, 16, 16, 100);
      font-size: 28px;
      /*text-align: left;*/
      margin-left: -150px;
    }
    .img_box {
      width: 169px;
      height: 54px;
      img {
       width: 169px;
        height: 54px;
      }
    }
  }
}
</style>